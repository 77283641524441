import { Component, OnInit } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { Subscription } from "rxjs";
import { IntegracaoService } from "src/app/services/integracao.service";
import { ModoEscuroService } from "src/app/services/modoEscuro.service";
import { TokenService } from "src/app/services/token.service";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})

export class HeaderComponent implements OnInit {

  logged: boolean = false;
  sidemenu: any = 'false';
  dadosUsuario: any;
  objUsuario: any;
  nomeUsuario: any = 'Usuário';
  mostrarBotao: boolean = true;
  contribuinteEmUso: string;
  objContribuinteEmUso: any;
  cadastroEmUso: string;
  objCadastroEmUso: any;
  nomeContribuinte: string;
  private subscription: Subscription;
  modoEscuro: boolean;

  constructor(
    private router: Router,
    private tokenService: TokenService,
    private integracaoService: IntegracaoService,
    private modoEscuroService: ModoEscuroService
  ) {
    this.router.events.subscribe((ev) => {
      if(ev instanceof NavigationEnd) {

        if (ev.url == '/inicio') {
          this.logged = true;
            setTimeout(() => {
              this.cadastroEmUso = localStorage.getItem('cadastroEmUso');
              this.objCadastroEmUso = JSON.parse(this.cadastroEmUso);
              this.contribuinteEmUso = localStorage.getItem('contribuinteEmUso');
              this.objContribuinteEmUso = JSON.parse(this.contribuinteEmUso);
              if (this.objCadastroEmUso) {
                this.nomeContribuinte = this.objCadastroEmUso.dscNomeCadastro;
              } else if (this.objContribuinteEmUso.contribuintes) {
                this.nomeContribuinte = this.objContribuinteEmUso.dscNomeCadastro;
              } else {
                this.nomeContribuinte = this.objContribuinteEmUso.dscRazaoSocial;
              }
          },200);
        } else if (ev.url == '/refis') {
          this.logged = true;
        } else if (ev.url == '/refis/requererAdesao') {
          this.logged = true;
        } else if (ev.url == '/refis/acompanharAdesao') {
          this.logged = true;
        } else if (ev.url == '/caixa-postal') {
          this.logged = true;
        } else if (ev.url == '/pre-acesso') {
          this.mostrarBotao = false;
        } else if (ev.url == '/produtor-rural/inscricao') {
          this.mostrarBotao = false;
        } else if (ev.url == '/produtor-rural') {
          this.mostrarBotao = false;
        } else if (ev.url == '/integracao') {
          this.mostrarBotao = false;
        } else if (ev.url == '/selecao-representante') {
          this.logged = true;
        } else if (ev.url == '/procuracao') {
          this.logged = true;
        } else if (ev.url =='/procuracao-outorgar') {
          this.logged = true;
        } else if (ev.url == '/duvidas-frequentes') {
          this.mostrarBotao = false;
        } else if (ev.url == '/inscricao') {
          this.logged = true;
        } else if (ev.url == '/acompanhamento') {
          this.logged = true;
        } else if (ev.url == '/') {
          this.logged = false;
          this.mostrarBotao = true;
        } else if (this.tokenService.hasToken()) {
          this.logged = true;
        } else {
          this.logged = false;
          this.mostrarBotao = true;
        }

      }
    })

    this.subscription = this.integracaoService.dadosIntegracao$.subscribe(() => {
      this.getDadosIntegracao();
    });
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.getDadosIntegracao();
    }, 1000)
  }

  getDadosIntegracao() {
    this.dadosUsuario = localStorage.getItem('dadosIntegracao');
    this.objUsuario = JSON.parse(this.dadosUsuario);
    this.nomeUsuario = this.objUsuario.sessao.cadastro.dscNomeCadastro;
  }

  entrar() {
    this.router.navigate(["pre-acesso"]);
  }

  logout() {
    localStorage.removeItem('sidemenu');
    localStorage.removeItem('dadosIntegracao');
    localStorage.removeItem('contribuinteEmUso');
    localStorage.removeItem('cadastroEmUso');
    localStorage.removeItem('perfilSelecionado');
    this.tokenService.removeToken();
    localStorage.removeItem('primeiroAcesso');
    this.sidemenu = 'false';
    window.location.href = "https://sso.acesso.gov.br/logout";
    window.location.href = "https://dfe.sefaz.to.gov.br";
  }

  abrirAjuda() {
    const link = "https://falabr.cgu.gov.br/publico/TO/Manifestacao/RegistrarManifestacao";
    window.open(link, '_blank');
  }

  ativarModoEscuro() {
    this.modoEscuro = !this.modoEscuro; 
    this.modoEscuroService.setModoEscuro(this.modoEscuro);
    if (this.modoEscuro) {
      document.body.classList.add('modo-escuro');
    } else {
      document.body.classList.remove('modo-escuro');
    }
  }
}

